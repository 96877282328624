<template>
  <div id="about">
    <CRow>
      <CCol lg="12">
        <div class="d-flex justify-content-center py-5">
          <div class="card-ow">
            <div class="card-logo">
              <img src="~@/assets/img/logo-white.svg" alt="ow-logo-white" />
            </div>
            <div class="card-footer">
              <div class="left-content">
                <b>v{{ version }}</b>
                <br />
                <small>MANAGER</small>
              </div>
              <div class="right-content">
                <a href="mailto:help@owinteractive.com"
                  >help@owinteractive.com</a
                >
                <br />
                <small>SUPORTE</small>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Sobre',
    titleTemplate: ' %s - Manager - Rodonaves Iveco'
  },

  data() {
    return {
      version: ''
    }
  },
  mounted() {
    this.getVersion()
  },
  methods: {
    getVersion() {
      this.version = process.env.VUE_APP_VERSION
    }
  }
}
</script>

<style lang="scss">
#about {
  .card-ow {
    border-radius: 3px;
    width: 400px;
    box-shadow: 0px 0px 52px -22px rgba(0, 0, 0, 0.75);

    .card-logo {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      width: 100%;
      background-color: black;
      display: flex;
      justify-content: center;
      padding: 40px 0;

      img {
        width: 100%;
        max-width: 125px;
      }
    }

    .card-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .left-content,
      .right-content {
        text-align: center;

        span {
          color: #dddddd;
        }
      }

      .left-content {
        position: relative;
        width: 30%;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-right: solid 1px #dddddd;
        }
      }

      .right-content {
        width: 70%;

        a {
          text-decoration: none;
          color: #3c4b64;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
